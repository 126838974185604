import zoom from 'chartjs-plugin-zoom';
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  data() {
    return {
      options: {
        legend: {
          // display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        pan: {
          enabled: true,
          mode: 'x',
        },
            zoom: {
              enabled: true,
              drag: {
                animationDuration: 1000
              },
              mode: 'x',
              speed: 0.05
            }
      },
    }
  },
  mixins: [reactiveProp],
  mounted() {
    this.addPlugin(zoom);

    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  }
}
