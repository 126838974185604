<template>
  <div>

    <template v-if="status">{{status}}</template>
    <template v-else>
      <h2>Collectes par semaine</h2>
      {{ pickups.length }} collectes effectuées.<br />

      <div class="chart">
        <line-chart
        :width="300" :height="400"
          :chart-data="datacollection"
        ></line-chart>
      </div>

      <!-- frequecies -->
      <h2>Ecarts des collectes (1 = une semaine) (depuis 2020!)</h2>
      <div class="frequencies">
        <div v-for="(diffs, cid) in frequencies" :key="'f'+cid">
          <span class="cid">{{cid}}#{{collectors[cid].label}} :</span> 
          <span v-for="(diff, i) in diffs" :key="'diff'+i" :class="'diff-' + diff">
            {{diff}},
          </span>  
        </div>
      </div>


      <h2>Carte des collectes</h2>
      <button class="i-button label" @click="rewind">&lt;</button>
      <button class="i-button label" @click="togglePlayPause">{{ play ? 'Pause' : 'Play' }}</button>
      <button class="i-button label" @click="advance">&gt;</button>
      Semaine 
      <select v-model="week" @change="setMapData" style="display: inline; width: initial">
        <option v-for="w in weeks" :key="'w'+w" :value="w">{{w}}</option>
      </select>
      Année
      <select v-model="year" @change="setMapData" style="display: inline; width: initial">
        <option v-for="y in years" :key="'y'+y" :value="y">{{y}}</option>
      </select>
      
      <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :attributionControl="false"
        :center="[10.32, 36.88]"
        :zoom="11"
        @load="onMapLoad"
      >
        <MglFullscreenControl position="top-right" />
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
        <MglScaleControl position="bottom-left" />
      </MglMap>
    </template>
  </div>
</template>

<script>
import LineChart from "./LineChart.js";

import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl,
} from "vue-mapbox";
import Mapbox from "mapbox-gl";
import { AllLayers } from "../../assets/layers";
import {
  addMobilePitchGesture,
  LanguageSelectorControl,
} from "../../assets/p";
let geoJson = {
  type: "FeatureCollection",
  features: [],
};

export default {
  name: "ModelIndex",
  components: {
    LineChart,
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,
  },
  data() {
    return {
      accessToken:
        "pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2pjZW0weGoxMWZkZDJ3cGVmYmZkbHVreiJ9.0dFhFtJJUEN2ziihdjzNwQ",
      mapStyle: AllLayers["where-streets"],
      mapbox: Mapbox,
      status: 'initializing map ...',
      collectors: [],
      pickups: [],
      routes: [],
      fromYear: 2014,
      toYear: 2021,
      year: 2014, week: 1,
      datacollection: {},
      play: false,
      frequencies: {},
      years: [],
      weeks: [],
    };
  },
  mounted() {
    for (let y = this.fromYear; y<= this.toYear; y++) this.years.push(y);
    for (let w = 1; w<= 52; w++) this.weeks.push(w);
    this.loadData();
  },

  methods: {
    onMapLoad(event) {
      const map = event.map;
      this.map = event.map;


      // faster scroll with mouse wheel
      map.scrollZoom.setWheelZoomRate(1 / 100);

      addMobilePitchGesture(map);
      map.addControl(new LanguageSelectorControl());

      map.addLayer({
        id: "collectors",
        type: "circle",
        source: { type: "geojson", data: geoJson },
        paint: {
          "circle-radius": [ "interpolate", ["exponential", 1.5], ["zoom"], 9, 2, 17, 5, ],
          "circle-color": ["get", "color"],
        },
      });
    },

    loadData() {
      this.status = 'loading data ...';
      this.$api.get("/pickups/statsPerWeek").then((j) => {
        this.status = 'Analysing ...';
        this.collectors = j.data.collectors;
        this.pickups = j.data.pickups.filter(p => 'done' === p.status);
        this.routes = j.data.routes;
        this.parseGraphData();
        this.analyseFrequency();
      });
    },

    parseGraphData() {
      const data = [];
      const routesData = [];
      const labels = [];
      const totals = {};
      const routesTotals = {};

      this.pickups.forEach(p => {
        if ('undefined' === typeof(totals[p.y])) totals[p.y] = {};
        if ('undefined' === typeof(totals[p.y][p.w])) totals[p.y][p.w] = 0;
        if ('undefined' === typeof(routesTotals[this.collectors[p.cid].rid])) routesTotals[this.collectors[p.cid].rid] = {};
        if ('undefined' === typeof(routesTotals[this.collectors[p.cid].rid][p.y])) routesTotals[this.collectors[p.cid].rid][p.y] = {};
        if ('undefined' === typeof(routesTotals[this.collectors[p.cid].rid][p.y][p.w])) routesTotals[this.collectors[p.cid].rid][p.y][p.w] = 0;
        totals[p.y][p.w]++;
        routesTotals[this.collectors[p.cid].rid][p.y][p.w]++;
      })
      for (let y = this.fromYear; y <= this.toYear; y++) {
        for (let w = 1; w < 53; w++) {
          // all days
          data.push(totals[y][w] ? totals[y][w] : 0);
          // everyday
          for (let rid in this.routes) {
            if (!routesData[rid]) routesData[rid] = [];
            routesData[rid].push(routesTotals[rid] && routesTotals[rid][y] && routesTotals[rid][y][w] ? routesTotals[rid][y][w] : 0);
          }
          labels.push(w + "/" + y);
        }
      }

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: "All",
            fill: true,
            backgroundColor: "#f87979",
            data: data,
          },
        ],
      };
      
      for (let rid in this.routes) {
        this.datacollection.datasets.push({
          label: this.routes[rid].label,
          fill: false,
          hidden: true,
          backgroundColor: this.routes[rid].color,
          data: routesData[rid],
        })
      }

      this.status = false;
    },

    setMapData() {
      const features = this.pickups
        .filter((p) => p.w == this.week && p.y == this.year)
        .map((p) => {
          const col = this.collectors[p.cid];
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [col.lon, col.lat],
            },
            properties: { color: col.rid ? this.routes[col.rid].color : 0 },
          };
        });
      geoJson.features = features;
      this.map.getSource("collectors").setData(geoJson);
    },

    advance() {
      this.week++;
      if (this.week > 52) {
        this.year++;
        this.week = 1;
      }
      if (this.year > this.toYear) {
        this.year = this.fromYear;
        this.week = 1;
      }
      this.setMapData();
      if (this.play) setTimeout(this.advance, this.play)
    },

    rewind() {
      this.week--;
      if (this.week < 1) {
        this.year--;
        this.week = 52;
      }
      if (this.year < this.fromYear) {
        this.year = this.toYear;
        this.week = 52;
      }
      this.setMapData();
      if (this.play) setTimeout(this.advance, this.play)
    },

    togglePlayPause() {
      if (this.play) {
        this.play = false;
      } else {
        this.play = 300;
        this.advance();
      }
    },

    analyseFrequency() {
      const cols = {};
      this.pickups.filter(p => p.y >= 2020).forEach(p => {
        if (!cols[p.cid]) cols[p.cid] = [];
        cols[p.cid].push((52 * p.y) + p.w);
      })

      for (let cid in cols) {
        const diffs = [];
        cols[cid].sort();
        for (let i=1; i<cols[cid].length; i++) {
          const diff = cols[cid][i] - cols[cid][i-1];
          diffs.push(diff ? diff : 1);
        }
        cols[cid] = diffs;
        if (!cols[cid].some(d => d===1)) delete cols[cid];
      }
      this.frequencies = cols;

    }
  },
};
</script>

<style>
@import "../../../node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "../../assets/sass/mapbox.scss";
</style>

<style lang="scss" scoped>
.chart {
  background: #fff;
  border: 1px solid #aaa;
  margin-bottom: 1em;
}

h2 {
  margin-top: 2em;
  font-size: 200%;
}

.frequencies {
  overflow: auto;
  height: 300px;

  .cid {
    font-weight: bold;
  }

  .diff-1 {
    color: red;
    font-weight: bold;
  }
}

</style>